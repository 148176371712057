import type { ComponentPropsWithoutRef } from 'react';
import { createContext, useContext, useMemo } from 'react';

interface ControlContextValue {
  labelProps: {
    htmlFor: string;
  };
  inputProps: Pick<ComponentPropsWithoutRef<'input'>, 'aria-invalid' | 'aria-errormessage' | 'aria-describedby'> & {
    id: string;
  };
  feedbackMessageProps: Record<
    'info' | 'error',
    {
      id: string;
    }
  >;
  error?: null | string;
}

const ControlContext = createContext<ControlContextValue | null>(null);

interface ControlProviderProps {
  id: string;
  info?: null | string;
  error?: null | string;
  children: React.ReactNode;
}

/** Provides correct attributes for a form control accessibility */
export function ControlProvider({ id, info, error, children }: ControlProviderProps) {
  // Generate correct attributes for a11y
  const value = useMemo(() => {
    const errorId = `${id}-error`;
    const infoId = `${id}-info`;

    const inputProps: ControlContextValue['inputProps'] = { id };

    if (error) {
      inputProps['aria-invalid'] = true;
      inputProps['aria-errormessage'] = errorId;
    }

    if (info) {
      inputProps['aria-describedby'] = infoId;
    }

    return {
      labelProps: { htmlFor: id },
      inputProps,
      feedbackMessageProps: {
        error: { id: errorId },
        info: { id: infoId },
      },
      error,
    };
  }, [id, info, error]);

  return <ControlContext.Provider value={value}>{children}</ControlContext.Provider>;
}

/** Retrieves correct attributes for a form control accessibility */
export function useControl(): Partial<ControlContextValue> {
  const control = useContext(ControlContext);

  return control ?? {};
}
